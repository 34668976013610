import React, { useState } from 'react'

import Accordion from '../../Accordion'

import { BlockAccordionsSection } from './BlockAccordions.styled'

const BlockAccordions = data => {
	const [activeIndex, setActiveIndex] = useState(0)

	return (
		<BlockAccordionsSection paddingTop={data.paddingTop} paddingBottom={data.paddingBottom}>
			{data.accordions.map((accordionData, index) => {
				const ariaExpanded = index === activeIndex

				return (
					<Accordion
						key={index}
						data={accordionData}
						index={index}
						ariaExpanded={ariaExpanded}
						onClick={() => setActiveIndex(index)}
					/>
				)
			})}
		</BlockAccordionsSection>
	)
}

export default BlockAccordions
