import React, { useRef } from 'react'

import { AccordionWrapper, AccordionTitle, AccordionContentWrapper, ExpandIcon } from './Accordion.styled'

const Accordion = props => {
	const contentWrapper = useRef(null)

	return (
		<AccordionWrapper onClick={props.onClick}>
			<AccordionTitle>
				{props?.data?.title}
				<ExpandIcon icon={props.ariaExpanded ? 'plus' : 'minus'} />
			</AccordionTitle>

			<AccordionContentWrapper
				ref={contentWrapper}
				height={contentWrapper.current && contentWrapper.current.scrollHeight + 100}
				open={props.ariaExpanded}
				dangerouslySetInnerHTML={{ __html: props?.data?.content }}
			/>
		</AccordionWrapper>
	)
}

export default Accordion
