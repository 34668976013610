import styled from 'styled-components'

import Section from '../../Section'
import Icon from '../../Icon'

const BlockAccordionsSection = styled(Section)``

const Accordion = styled.div`
	display: flex;
	flex-direction: column;
	cursor: pointer;
`

const AccordionTitle = styled.h3`
	display: flex;
	justify-content: space-between;
	background-color: #f7f7f7;
	font-size: 20px;
	font-weight: 400;
	padding: 20px 30px;
	margin: 0;
`

const AccordionContentWrapper = styled.div`
	padding: 20px;
	border-bottom: 1px solid #f7f7f7;
	border-left: 1px solid #f7f7f7;
	border-right: 1px solid #f7f7f7;

	display: ${props => (props.open ? 'block' : 'none')};

	height: ${props => (props.open ? '100%' : '0')};
`

const ExpandIcon = styled(Icon)`
	color: #707070;
`

export { BlockAccordionsSection, Accordion, AccordionTitle, AccordionContentWrapper, ExpandIcon }
