import styled from 'styled-components'

import Icon from '../Icon'

const AccordionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	cursor: pointer;
`

const AccordionTitle = styled.h3`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #f7f7f7;
	font-size: 20px;
	font-weight: 400;
	padding: 20px 30px;
	margin: 0;
`

const AccordionContentWrapper = styled.div`
	border-bottom: 1px solid #f7f7f7;
	border-left: 1px solid #f7f7f7;
	border-right: 1px solid #f7f7f7;
	max-height: ${props => (props.open ? props.height : '0')}px;
	padding: ${props => (props.open ? '20px' : '0 20px 0 20px')};
	transition: ease 0.5s;
	overflow: hidden;
`

const ExpandIcon = styled(Icon)`
	color: #707070;
`
export { AccordionWrapper, AccordionTitle, AccordionContentWrapper, ExpandIcon }
